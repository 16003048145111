import Layout from '@/components/layout';
import PractitionersSearchSection from '@/components/practitioners/search-section';
import { Link } from 'gatsby';
import React from 'react';

const ListPractitionersByRegionProvinceCity = ({
  pageContext: { practitioners },
}) => {
  let currentCategory;
  return (
    <Layout>
      <section className="section has-background-secondary has-background-primary">
        <div className="container">
          <h1 className="title is-4 has-text-centered is-secondary is-primary">
            Liste de nos thérapeutes classés par Catégorie
          </h1>
        </div>
      </section>
      <PractitionersSearchSection />
      <section className="section">
        <div className="container">
          {practitioners.map((currentPractitioner) => {
            const newCategory =
              currentCategory !== currentPractitioner.category.title;
            if (newCategory)
              currentCategory = currentPractitioner.category.title;

            return (
              <>
                {newCategory && currentCategory && (
                  <>
                    <hr className="mt-6" />
                    <h2 className="title is-2 mt-6">{currentCategory}</h2>
                  </>
                )}
                {currentPractitioner.title && (
                  <>
                    <h3 className="mt-3 title is-5">
                      <Link
                        to={`${currentPractitioner.meta.websiteSlug}#content`}
                      >
                        {currentPractitioner.title}
                      </Link>
                    </h3>
                    {currentPractitioner.category.title && (
                      <div className="subtitle is-6">
                        {currentPractitioner.content.accompanimentsDomains
                          .length ? (
                          <>
                            (
                            {currentPractitioner.content.accompanimentsDomains.join(
                              ', ',
                            )}
                            )
                          </>
                        ) : undefined}
                      </div>
                    )}
                    {currentPractitioner.content.accompanimentsDomains && (
                      <p className="mb-6" />
                    )}
                  </>
                )}
              </>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};
export default ListPractitionersByRegionProvinceCity;
